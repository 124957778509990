/**
 * @license
 * @copyright Copyright Motili Inc., 2017 All Rights Reserved
 */

import moment from 'moment-timezone';
import merge from 'lodash/merge';
import * as ApiService from './ApiService';

const baseUrl = 'billings';

export async function findWithCount(processedFilters) {
    const filterFields = {
        filter: {
            include: ['client'],
        },
    };
    const query = merge({}, filterFields, { filter: processedFilters });
    const invoices = await ApiService.get(true, `${baseUrl}/invoices`, query);
    const countResp = await ApiService.get(
        true,
        `${baseUrl}/invoices/count`,
        query
    );

    return { invoices, count: countResp.count };
}

export function downloadInvoice(
    ids,
    clientId,
    orderedOnBehalf,
    downloadForClient = false
) {
    const query = {
        clientId,
        orderedOnBehalf: !!orderedOnBehalf,
        downloadForClient: !!downloadForClient,
    };

    return ApiService.post(true, `${baseUrl}/invoices/download`, ids, query);
}

export function findDownloadLog(query) {
    return ApiService.get(true, `${baseUrl}/invoices/downloadLogs`, query);
}

export function countDownloadLog(query) {
    return ApiService.get(
        true,
        `${baseUrl}/invoices/downloadLogs/count`,
        query
    );
}

export function processFilters(activeFilters, activeQuery) {
    const query = merge({}, activeQuery, {
        where: {
            and: [],
        },
    });

    if (!activeFilters.length) {
        return query;
    }

    const orderedOnBehalfClientIds = [];
    const payeeClientIds = [];

    activeFilters.forEach(filter => {
        switch (filter.target) {
            // advanced filters
            case 'status':
                if (filter.term === 'INVOICE_DELETED') {
                    query.where.and.push({
                        state: 'DELETED',
                    });
                } else {
                    query.where.and.push({
                        downloaded: filter.term === 'INVOICE_DOWNLOADED',
                        state: 'ACTIVE',
                    });
                }
                break;
            case 'orderedOnBehalfClientId':
                orderedOnBehalfClientIds.push(filter.term);
                break;
            case 'payeeClientId':
                payeeClientIds.push(filter.term);
                break;
            case 'lackingSerial':
                query.where.and.push({
                    missingSerial: true,
                });
                break;
            case 'amountGreater':
                query.where.and.push({
                    amountTotal: {
                        gt: filter.term,
                    },
                });
                break;
            case 'amountLess':
                query.where.and.push({
                    amountTotal: {
                        lt: filter.term,
                    },
                });
                break;
            case 'dueDateBefore':
                query.where.and.push({
                    dueDate: {
                        lt: moment(filter.term)
                            .endOf('day')
                            .subtract(1, 'days'),
                    },
                });
                break;
            case 'dueDateAfter':
                query.where.and.push({
                    dueDate: {
                        gt: moment(filter.term)
                            .endOf('day')
                            .subtract(1, 'days'),
                    },
                });
                break;
            case 'invoiceDateBefore':
                query.where.and.push({
                    invoiceDate: {
                        lt: moment(filter.term)
                            .endOf('day')
                            .subtract(1, 'days'),
                    },
                });
                break;
            case 'invoiceDateAfter':
                query.where.and.push({
                    invoiceDate: {
                        gt: moment(filter.term)
                            .endOf('day')
                            .subtract(1, 'days'),
                    },
                });
                break;
            case 'updatedBefore':
                query.where.and.push({
                    updated: {
                        lt: moment(filter.term)
                            .endOf('day')
                            .subtract(1, 'days'),
                    },
                });
                break;
            case 'updatedAfter':
                query.where.and.push({
                    updated: {
                        gt: moment(filter.term)
                            .endOf('day')
                            .subtract(1, 'days'),
                    },
                });
                break;
            // search filters
            case 'INVOICE_NUMBER':
                query.where.and.push({
                    invoiceNumber: {
                        like: `%${filter.term}%`,
                        options: 'i',
                    },
                });
                break;
            case 'PO_NUMBER':
                query.where.and.push({
                    customerPoNumber: {
                        like: `%${filter.term}%`,
                        options: 'i',
                    },
                });
                break;
            default:
        }
    });

    if (orderedOnBehalfClientIds.length > 0) {
        query.where.and.push({
            orderedOnBehalfClientId: { inq: orderedOnBehalfClientIds },
        });
    }
    if (payeeClientIds.length > 0) {
        query.where.and.push({
            clientId: { inq: payeeClientIds },
        });
    }

    return query;
}

export const getContractorInvoiceAccess = contractorId => {
    return ApiService.get(
        true,
        `${baseUrl}/contractorInvoiceAccess/${contractorId}`
    );
};
